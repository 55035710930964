import portada from "../img/productos/cortinas/1.png";
import { IoIosArrowDropright } from "react-icons/io";
import CotizaOption from "../hooks/CotizaOption";
import img2 from "../img/cambn/prueba.png";
import { NavLink } from "react-router-dom";
import img1 from "../img/cambn/2.png";
import img5 from "../img/cambn/5.png";
import img6 from "../img/cambn/6.png";
import img7 from "../img/cambn/7.png";
import img8 from "../img/cambn/8.png";
import img9 from "../img/cambn/23.png";
import { useEffect } from "react";


const DuelasAccesorios = ({ setOther }) => {
  useEffect(() => {
    setOther(false);
  }, [setOther]);

  return (

    <>
      <div className="padre">
        <div className="lineas_fondo w-100 h-100">
          <div className="first w-100">


          </div>
          <div className="second w-100">

          </div>
        </div>
      </div>

      <section className="mb-5">
        <figure>
          <img src={portada} className=" w-100 h-100 " alt=" portada" />
        </figure>
      </section>

      <section className="container mb-5  overflow-hidden">
        <div className="mb-5 d-flex justify-content-center " data-aos="zoom-in" >
          <h2 className="texttitulo text-wrap text-center tptelefono text-uppercase">Duelas y Accesorios</h2>
        </div>

        <div className="container d-flex justify-content-center">
          <figure >
            <img className="muestrac" src={img2} alt="muestra del material " />
          </figure>
        </div>
        <div className="container  ">
          <h3 className="texto_2  text-center tptelefono"> ¡Ofrece a tus clientes lo mejor!</h3>
        </div>
      </section >

      <section className="container mb-5 position-relative">
        <div className="overflow-hidden">
          <div className="d-flex justify-content-start estilost" data-aos="zoom-in" >
            <h2 className=" texto_2 tptelefono">estilos de duelas</h2>
          </div>
          <div className=" d-flex gap-3 telefono  ">
            <figure className=" w-100 " data-aos="fade-right">
              <img className="w-100 h-100" src={img5} alt="estilos de duelas" />
            </figure>
            <div className=" d-flex flex-column" data-aos="fade-left">
              <p className="duelasp " >
                Tenemos una amplia variedad de diseños ​de duelas para
                satisfacer las ​necesidades de tus clientes.
              </p>
              <div className="d-flex gap-1 align-items-center">
                  <span className="arrow-round-green">
                      <IoIosArrowDropright size={30} />
                  </span>
                  <NavLink to="/duelas/estilos">
                      <span className="fw-bold">Más información </span>
                  </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="position-relative container mb-5">
        <div className="overflow-hidden">
          <div className=" d-flex gap-3 telefono-reverse" data-aos="fade-right">
            <div className=" w-50 d-flex flex-column justify-content-center telefono-w-100 ">
              <h2 className=" texto_2 tptelefono">Accesorios</h2>
              <p className=" cortinasp ">Ofrecemos a distribuidores y ​mayoristas una amplia variedad ​de materiales y accesorios ​tales como tambores, resortes, ​guías, ganchos y mucho más ​para fabricar cortinas metálicas ​manuales y motorizadas.</p>
              <div className="d-flex gap-1 align-items-center">
                  <span className="arrow-round-green">
                      <IoIosArrowDropright size={30} />
                  </span>
                  <NavLink to="/cortinas/accesorios">
                      <span className="fw-bold">Más información </span>
                  </NavLink>
              </div>
            </div>
            <div className="imagen_terjeta">
              <figure className="w-100 h-100">
                <img
                  className="w-100 h-100"
                  src={img1}
                  alt="muestra de la persiana"
                />
              </figure>
            </div>
          </div>
        </div>
      </section >
     

      <div className="container daralinicacio  overflow-hedden">
        <figure data-aos="flip-left">
          <img className="w-100 h-100" src={img6} alt="al horno" />
        </figure>
      </div>
      <div className="container subirseñor daralinicacio overflow-hidden">
        <figure data-aos="flip-right">
          <img className="w-100 h-100 spintando" src={img7} alt="señorpintando" />
        </figure>
      </div>

      <section>
        <div className=" daralinicacio fondosombra">
          <div className="  daralinicacio  " data-aos="fade-up">
            <figure>
              <img className="w-100 h-100 spintando" src={img9} alt="fondo sombreado" />
            </figure>
          </div>
        </div>
      </section>

      <section className=" padre overflow-hidden seccionlineas soloariba  ">
        <div className="transfom w-100 "></div>
        <div className="container d-flex gap-3 telefono tablet  " id="miancho">
          <div className="d-flex align-items-center bajarletras" >
            <div>
              <p className="letraslineas">
                ¡En DICET cuentas con toda la ​asesoría necesaria para que puedas
                ofrecer la mejor opción a tus clientes!
              </p>
              <p className="letraslineas">
                Con nosotros encuentras de todo ​para Cortinas Metálicas.
              </p>
            </div>
          </div>
          <div className="contworker">
            <figure className="mwtr">
              <img
                className="trabajador"
                src={img8}
                alt="trabajador"
              />
            </figure>
          </div>
        </div>
      </section>

      <CotizaOption title={'¡Cotiza hoy mismo!'}/>

    </>
  );
};

export default DuelasAccesorios;
