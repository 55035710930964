import portada from "../img/portadas/Duelas_de_Aluminio.png";
import img1 from "../img/productos/duelas/accesorios/1.png";
import { IoIosArrowDropright } from "react-icons/io";
import CotizaOption from "../hooks/CotizaOption";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";


const Duelas = ({ setOther }) => {
    useEffect(() => {
        setOther(false);
    }, [setOther]);

    return (
        <>
            <section className="mb-5 ">
                <figure>
                    <img src={portada} className=" w-100 h-100 " alt=" portada" />
                </figure>
            </section>
            <section className="mb-5 overflow-hidden">
                <div className=" container text-center" data-aos="zoom-in">
                    <h2 className="etitulo alinearletraselevador m-0 text-uppercase">duelas de aluminio</h2>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img1} alt="Accesorios"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">Accesorios de duelas ​de aluminio</h2>
                            <p>
                                Ofrecemos a distribuidores y ​mayoristas una amplia variedad ​de materiales y accesorios ​tales como tambores, resortes, ​guías, ganchos y mucho más ​para fabricar cortinas metálicas ​manuales y motorizadas.
                            </p>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoIosArrowDropright size={30} />
                                </span>
                                <NavLink to="/duelas/aluminio/accesorios">
                                    <span className="fw-bold">Más información </span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <CotizaOption title={'¡Cotiza hoy mismo!'}/>
        </>
    );
};

export default Duelas;
