import img5 from "../img/productos/cortinas/Cortina_plana_Microperforada.png";
import img3 from "../img/productos/cortinas/Cortina_color_Amarillo.png";
import img4 from "../img/productos/cortinas/Cortina_plana_Galvalum.png";
import img2 from "../img/productos/cortinas/Cortina_color_Madera.png";
import img7 from "../img/productos/cortinas/Cortina_Color_Negro.png";
import img6 from "../img/productos/cortinas/Cortina_Color_Azul.png";
import img8 from "../img/productos/cortinas/Cortina_Troquelada.png";
import img1 from "../img/productos/cortinas/Cortina_Galvalum.png";
import portada from "../img/portadas/Cortinas.png";
import CotizaOption from "../hooks/CotizaOption";
import BtnCotiza from "../hooks/BtnCotizar";
import { useEffect } from "react";


const Cortinas = ({ setOther }) => {
  useEffect(() => {
    setOther(false);
  }, [setOther]);

  return (

    <>
    {
      /*<div className="padre">
        <div className="lineas_fondo w-100 h-100">
          <div className="first w-100"></div>
          <div className="second w-100"></div>
        </div>
      </div>*/}

      <section className="mb-5">
        <figure>
          <img src={portada} className=" w-100 h-100 " alt=" portada" />
        </figure>
      </section>

      <section className="container mb-5  overflow-hidden">
        <div className="d-flex justify-content-center " data-aos="zoom-in" >
          <h2 className="texttitulo text-wrap text-center tptelefono text-uppercase">Cortinas Metálicas</h2>
        </div>
      </section >

      <section className="mb-5">
          <div className="container overflow-hidden " >
              <div className="d-flex justify-content-end" data-aos="fade-left">
                  <div className="w-50 mb-5 telefono-w-100">
                      <figure>
                          <img className="w-100 h-100" src={img1} alt="Cortina Galvalum" />
                      </figure>
                      <h2 className="fw-bold title-sections text-start">Cortina Galvalum</h2>
                      <BtnCotiza/>
                  </div>
              </div>
          </div>
      </section>

      <section className="mb-5">
          <div className="container overflow-hidden " >
              <div className="d-flex justify-content-start" data-aos="fade-right">
                  <div className="w-50 mb-5 telefono-w-100">
                      <figure>
                          <img className="w-100 h-100" src={img2} alt="Cortina color Madera" />
                      </figure>
                      <h2 className="fw-bold title-sections text-start">Cortina color Madera</h2>
                      <BtnCotiza/>
                  </div>
              </div>
          </div>
      </section>

      <section className="mb-5">
          <div className="container overflow-hidden " >
              <div className="d-flex justify-content-end" data-aos="fade-left">
                  <div className="w-50 mb-5 telefono-w-100">
                      <figure>
                          <img className="w-100 h-100" src={img3} alt="Cortina color Amarillo" />
                      </figure>
                      <h2 className="fw-bold title-sections text-start">Cortina color Amarillo</h2>
                      <BtnCotiza/>
                  </div>
              </div>
          </div>
      </section>

      <section className="mb-5">
          <div className="container overflow-hidden " >
              <div className="d-flex justify-content-start" data-aos="fade-right">
                  <div className="w-50 mb-5 telefono-w-100">
                      <figure>
                          <img className="w-100 h-100" src={img4} alt="Cortina Plana Galvalum" />
                      </figure>
                      <h2 className="fw-bold title-sections text-start">Cortina plana Galvalum</h2>
                      <BtnCotiza/>
                  </div>
              </div>
          </div>
      </section>

      <section className="mb-5">
          <div className="container overflow-hidden " >
              <div className="d-flex justify-content-end" data-aos="fade-left">
                  <div className="w-50 mb-5 telefono-w-100">
                      <figure>
                          <img className="w-100 h-100" src={img5} alt="Cortina Plana Microperforada" />
                      </figure>
                      <h2 className="fw-bold title-sections text-start">Cortina plana Microperforada</h2>
                      <BtnCotiza/>
                  </div>
              </div>
          </div>
      </section>

      <section className="mb-5">
          <div className="container overflow-hidden " >
              <div className="d-flex justify-content-start" data-aos="fade-right">
                  <div className="w-50 mb-5 telefono-w-100">
                      <figure>
                          <img className="w-100 h-100" src={img6} alt="Cortina color azul" />
                      </figure>
                      <h2 className="fw-bold title-sections text-start">Cortina color Azul</h2>
                      <BtnCotiza/>
                  </div>
              </div>
          </div>
      </section>

      <section className="mb-5">
          <div className="container overflow-hidden " >
              <div className="d-flex justify-content-end" data-aos="fade-left">
                  <div className="w-50 mb-5 telefono-w-100">
                      <figure>
                          <img className="w-100 h-100" src={img7} alt="Cortina color negro" />
                      </figure>
                      <h2 className="fw-bold title-sections text-start">Cortina color Negro</h2>
                      <BtnCotiza/>
                  </div>
              </div>
          </div>
      </section>

      <section className="mb-5">
          <div className="container overflow-hidden " >
              <div className="d-flex justify-content-start" data-aos="fade-right">
                  <div className="w-50 mb-5 telefono-w-100">
                      <figure>
                          <img className="w-100 h-100" src={img8} alt="Cortina Troquelada" />
                      </figure>
                      <h2 className="fw-bold title-sections text-start">Cortina Troquelada</h2>
                      <BtnCotiza/>
                  </div>
              </div>
          </div>
      </section>

      <CotizaOption title={'¡Cotiza hoy mismo!'}/>

    </>
  );
};

export default Cortinas;
