import { useEffect } from "react"
import img1 from '../img/about/about.png';
import CotizaOption from "../hooks/CotizaOption";

const About = ({ setOther }) => {

    useEffect(() => {
        setOther(true)
    }, [setOther])

    return (
        <>
            <section className='marginTop mb-5 section-about'>
                <div className="container">
                    <div className='mb-3 m-auto containter-title-about'>
                        <h2 className="text-uppercase">acerca de nosotros</h2>
                    </div>
                    <div className='d-flex justify-content-center container-description-about'>
                        <div className='description-about mb-5'>
                            <p className='mb-4 motoresp'>
                                Nos especializamos en atención personalizada al integrador, instalador, herrero y emprendedor, asesorando en la compra de nuestros productos. El stock garantizado nos diferencia, el tiempo de entrega a tiempo es nuestro poder. Atendemos a todos las empresas, talleres, ventas de Cortinas metálicas enrollables, Toldos eléctricos retractiles, persianas tipo roller de exterior e interior.
                            </p>
                            <p className=" motoresp" >
                                También distribuimos a nivel regional y nacional, muebles, telas para tapicería fina y sombrillas, todo esto forma parte del gran portafolio de productos que, como nosotros pensamos, puede hacer crecer cualquier negocio al brindarles únicamente productos de calidad con disponibilidad inmediata. No lo dude, contáctenos y conozcamos, le gustara trabajar con nosotros. Atentamente, Equipo DICET GUATEMALA.
                            </p>
                        </div>
                        <div className='container-img-about'>
                            <figure>
                                <img src={img1} alt='Nosotros' />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <CotizaOption title={'¡Cotiza hoy mismo!'}/>
        </>
    )
}

export default About