import { FaFacebookSquare } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { NavLink } from "react-router-dom"
import burbuja from "../img/home/burbuja.png";
import logo from "../img/logo1.png"

const SubFooter = () => {

    return(
        <section className='daralinicacio mb-0 overflow-hidden position-relative'>
            <div className="position-absolute z-n1 start-0 top-0">
                <figure>
                    <img src={burbuja} className="w-100 h-100" alt="burbuja"/>
                </figure>
            </div>
            
            <div className="container">
                <div className="my-5">
                    <div className="contenedor_news_subFooter text-center">
                        <h4 className="mb-0"><strong>¿Listo para cotizar a tu cliente?</strong></h4>
                        <h4 className="mb-0">Ya sea para el hogar, empresa, oficina o local</h4>
                        <h4 className="mb-0">¡en DICET lo encuentras!</h4>
                    </div>
                </div>
                <div className="my-5">
                    <figure className="d-flex justify-content-center">
                        <img src={logo} className="img_logo_subFooter" alt="DICET"/>
                    </figure>
                </div>
                <div className="my-5 row">
                    <div className="col">
                        <h2 className="fw-bold title-sections">Redes Sociales</h2>
                        <h6 className="fw-bold title-sections">Redes Sociales</h6>
                        <p className="mb-0 d-flex gap-1">
                            <NavLink to="https://www.instagram.com/dicet_gt/" rel="noopener noreferrer nofollow" target="_blank">
                                <span className="title-sections">dicet_gt</span>
                                <span><RiInstagramFill /></span>
                            </NavLink>
                        </p>
                        <p className="mb-0 d-flex gap-1">
                            <NavLink to="https://www.facebook.com/DICETtudistribuidora" rel="noopener noreferrer nofollow" target="_blank">
                                <span className="title-sections">/DICETtudistribuidora</span>
                                <span><FaFacebookSquare /></span>
                            </NavLink>
                        </p>
                    </div>
                    <div className="col">
                        <h2 className="fw-bold title-sections">Servicios</h2>
                        <p className="mb-0">
                            <NavLink to="/cortinas"><span className="title-sections">Cortinas Metálicas</span></NavLink>
                        </p>
                        <p className="mb-0">
                            <NavLink to="/cortinas/hawainas"><span className="title-sections">Cortinas Hawainas</span></NavLink>
                        </p>
                        <p className="mb-0">
                            <NavLink to="/duelas"><span className="title-sections">Duelas de Aluminio</span></NavLink>
                        </p>
                        <p className="mb-0">
                            <NavLink to="/duelas/accesorios"><span className="title-sections">Duelas y Accesorios</span></NavLink>
                        </p>
                        <p className="mb-0">
                            <NavLink to="/motores"><span className="title-sections">Motores</span></NavLink>
                        </p>
                        <p className="mb-0">
                            <NavLink to="/mecanismos/percianas/metalicas"><span className="title-sections">Mecanismos para persiana metálica</span></NavLink>
                        </p>
                        <p className="mb-0">
                            <NavLink to="/elevadores"><span className="title-sections">Elevadores de Parqueo</span></NavLink>
                        </p>
                        <p className="mb-0">
                            <NavLink to="/estructuras"><span className="title-sections">Sombrillas Económicas</span></NavLink>
                        </p>
                        <p className="mb-0">
                            <NavLink to="/toldos"><span className="title-sections">Toldos Retráctiles</span></NavLink>
                        </p>
                        <p className="mb-0">
                            <NavLink to="/telas"><span className="title-sections">Telas para Sombras</span></NavLink>
                        </p>
                        <p className="mb-0">
                            <NavLink to="/rollers"><span className="title-sections">Rollers de Exterior</span></NavLink>
                        </p>
                        <p className="mb-0">
                            <NavLink to="/rollers/accesorios"><span className="title-sections">Accesorios para Rollers</span></NavLink>
                        </p>
                        <p className="mb-0">
                            <NavLink to="/muebles"><span className="title-sections">Muebles Económicos</span></NavLink>
                        </p>
                    </div>
                    <div className="col">
                        <h2 className="fw-bold title-sections">Contáctanos</h2>
                        <h6 className="fw-bold title-sections mb-0">Ubicaciones</h6>
                        <ul className="title-sections directions">
                            <li>9a. av. 2-94 Zona 2 de Mixco Colonia Alvarado, ​Guatemala</li>
                            <li>17 calle 22-74 Zona 10, Colonia Alcázar, Guatemala</li>
                            <li>7a. av. D3-38B Zona 5, Ofibodegas Los Altos No.12, Quetzaltenango</li>
                            <li>2a calle 2-06 Zona 7, Cobán AV Residenciales Imperial</li>
                        </ul>
                        <h6 className="fw-bold title-sections mb-0">Ventas</h6>
                        <p className="mb-0">
                            <NavLink to="tel:+50230478057">
                                <span className="title-sections">(502) 3047-8057</span>
                            </NavLink>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SubFooter