import img6 from "../img/productos/estructuras/sombrilla_doble1.png";
import img5 from "../img/productos/estructuras/sombrilla_doble.png";
import img3 from "../img/productos/estructuras/poste_central.png";
import img1 from "../img/productos/estructuras/sombrilla1.png";
import img2 from "../img/productos/estructuras/base_cruz.png";
import img4 from "../img/productos/estructuras/base2.png";
import estructura1 from "../img/productos/estructuras/59.png";
import estructura2 from "../img/productos/estructuras/60.png";
import portada from "../img/portadas/Sombrillas.png";
import CotizaOption from "../hooks/CotizaOption";
import BtnCotiza from "../hooks/BtnCotizar";
import { GoDotFill } from "react-icons/go";
import { useEffect } from "react";

const Estructuras = ({ setOther }) => {
    useEffect(() => {
        setOther(false);
    }, [setOther]);

    return (
        <>
            <section className="mb-5 ">
                <figure>
                    <img src={portada} className=" w-100 h-100 " alt=" portada" />
                </figure>
            </section>
            <section className="mb-5 overflow-hidden">
                <div className=" container text-center" data-aos="zoom-in">
                    <h2 className="etitulo alinearletraselevador m-0 text-uppercase">sombrillas económicas</h2>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img1} alt="Sombrilla Económica 1" />
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-center flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections font-size-titles">Medidas</h2>
                            <h3 className="font-size-descriptions">3x3 Q6,500</h3>
                            <h3 className="font-size-descriptions">4x3 Q9,500</h3>
                            <BtnCotiza/>
                        </div>
                    </div>
                    <div className="d-flex flex-column mb-5">
                        <div className="d-flex gap-1 align-items-center">
                            <span>
                                <GoDotFill size={20} />
                            </span>
                            <h3>Aluminio poste 100x60x2.5mm</h3>
                        </div>
                        <div className="d-flex gap-1 align-items-center">
                            <span>
                                <GoDotFill size={20} />
                            </span>
                            <h3>Aluminio poste para colgar 25x40x2,2 mm 8</h3>
                        </div>
                        <div className="d-flex gap-1 align-items-center">
                            <span>
                                <GoDotFill size={20} />
                            </span>
                            <h3>Aluminio Costillas 20x33x1.3mm</h3>
                        </div>
                        <div className="d-flex gap-1 align-items-center">
                            <span>
                                <GoDotFill size={20} />
                            </span>
                            <h3>Tejido acrílico 260 g/m2, con PA con ventilación sin solapa</h3>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">Base de Sombrilla</h2>
                            <h3>Base en cruz de acero</h3>
                            <h3>Precio Q850</h3>
                            <h3>Medidas 0.89x 0.92</h3>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img2} alt="Base de sombrilla" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img3} alt="Sombrilla poste central" />
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-end flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">Sombrilla poste central</h2>
                            <h3>2.50x2.50 Q1,800</h3>
                            <h3>3x3 Q2,000</h3>
                            <h3>Tejido color beige</h3>
                            <h3>Poste de aluminio</h3>
                            <h3>Color blanco</h3>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">Base de Sombrilla</h2>
                            <h3>Precio Q800.00</h3>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img4} alt="Base de sombrilla" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img6} alt="Sombrilla doble" />
                            </figure>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img5} alt="Sombrilla doble" />
                            </figure>
                        </div>
                    </div>
                    <div className="w-50 d-flex flex-column align-self-center telefono-w-100">
                        <h2 className="text-uppercase fw-bold title-sections font-size-titles">Sombrilla doble</h2>
                        <h3>3x3 Q 13,800</h3>
                        <h3>Incluye base</h3>
                        <h3>Tejido color beige</h3>
                        <h3>Poste color café</h3>
                        <BtnCotiza/>
                    </div>
                </div>
            </section>

            <section className="my-5 overflow-hidden">
                <div className=" container text-center" data-aos="zoom-in">
                    <h2 className="etitulo alinearletraselevador m-0 text-uppercase">ESTRUCTURAS DE ALUMINIO ​PARA SOMBRILLAS</h2>
                </div>
            </section>

            <section className="mb-5 overflow-hidden">
                <div className="container d-flex container-movil mb-5 position-relative" data-aos="zoom-out">
                    <div className="container-content">
                        <h3>Fabricamos estructuras de aluminio ​resistentes y elegantes para sombrillas, ​garantizando durabilidad en áreas de ​exterior.</h3>
                        <h2 className="text-uppercase fw-bold title-sections">para tus sombrillas</h2>
                        <h3>3 metros de diámetro</h3>
                        <h3>Color Gris</h3>
                        <h3>Precio Q. 2,500.00</h3>
                        <div className="d-flex"><BtnCotiza/></div>
                    </div>
                    <div className="content-img-movil">
                        <div className="d-flex align-items-center flex-column content-img-movil-hijo">
                            <figure className="estructuras2 m-0">
                                <img src={estructura1} className="w-100 h-100" alt="estructura"/>
                            </figure>
                            <figure className="m-0 estructuras3">
                                <img src={estructura2} className="object-fit-cover w-100 h-100" alt="estructura"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>











           { /*<section className="daralinicacio overflow-hidden">
                <div className="container d-flex container-movil text-center mb-5 position-relative" data-aos="zoom-out">
                    <div className="container-content">
                        <h2 className="fw-bold text-uppercase title-estructuras title-estructuras1">estructuras de</h2>
                        <h2 className="fw-bold text-uppercase title-estructuras title-estructuras2">aluminio</h2>
                        <p className="description-estructuras">Para tus sombrillas</p>
                    </div>
                    <div className="d-flex align-items-center content-img-movil">
                        <figure className="estructuras1 m-0">
                            <img src={estructura1} className="w-100 h-100" alt="estructura"/>
                        </figure>
                        <div className="d-flex flex-column content-img-movil-hijo">
                            <figure className="estructuras2 m-0">
                                <img src={estructura3} className="w-100 h-100" alt="estructura"/>
                            </figure>
                            <figure className="m-0 estructuras3">
                                <img src={estructura2} className="object-fit-cover w-100 h-100" alt="estructura"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>*/
}

            <CotizaOption title={'¡Cotiza hoy mismo!'}/>
        </>
    );
};

export default Estructuras;
