//import { IoMdArrowDropright } from "react-icons/io";
import { NavLink } from "react-router-dom";

const BtnCotiza = () => {

    return (
        <NavLink className="btnCotizar" to="https://wa.me/+50230478057" rel="noopener noreferrer nofollow" target="_blank">
            <span>Cotizar</span>
        </NavLink>
    )
}
export default BtnCotiza