import portada from "../img/portadas/15.png";
import img1 from "../img/productos/motores/rib/15.png";
import img2 from "../img/productos/motores/rib/14.png";
import img3 from "../img/productos/motores/rib/accesorios/216.png";
import img4 from "../img/productos/motores/rib/accesorios/217.png";
import img5 from "../img/productos/motores/rib/accesorios/218.png";
import img6 from "../img/productos/motores/rib/accesorios/219.png";
import img7 from "../img/productos/motores/rib/accesorios/220.png";
import img8 from "../img/productos/motores/rib/accesorios/222.png";
import img9 from "../img/productos/motores/rib/accesorios/221.png";
import CotizaOption from "../hooks/CotizaOption";
import BtnCotiza from "../hooks/BtnCotizar";
import { useEffect } from "react";


const MotoresRib = ({ setOther }) => {
    useEffect(() => {
        setOther(false);
    }, [setOther]);

    return (
        <>
            <section className="mb-5 ">
                <figure>
                    <img src={portada} className=" w-100 h-100 " alt=" portada" />
                </figure>
            </section>
            <section className="mb-5 overflow-hidden">
                <div className=" container text-center" data-aos="zoom-in">
                    <h2 className="etitulo alinearletraselevador m-0 text-uppercase">motores jolly</h2>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">Jolly 20</h2>
                            <h4>Electrofreno</h4>
                            <h4>Corona 20cm</h4>
                            <h4>Para tubo 11/2"</h4>
                            <h4>Capacidad: 170kg / 14 m2</h4>
                            <h4>Fuentes de alimentación: 110v-120v</h4>
                            <h4>Revoluciones por minuto: R.P.M=11R/MIN</h4>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img1} alt="jolly"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img1} alt="jolly"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">Jolly 22</h2>
                            <h4>Electrofreno</h4>
                            <h4>Corona 22cm</h4>
                            <h4>Para tubo 2"</h4>
                            <h4>Capacidad: 220KG / 18m2</h4>
                            <h4>Fuentes de alimentación: 110v-120v</h4>
                            <h4>Revoluciones por minuto: R.P.M=10R/MIN</h4>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">Jolly 24</h2>
                            <h4>Electrofreno</h4>
                            <h4>Corona 24cm</h4>
                            <h4>Para tubo 2 1/2"</h4>
                            <h4>Capacidad: 300kg /22m2</h4>
                            <h4>Fuentes de alimentación: 110v-120v</h4>
                            <h4>Revoluciones por minuto: R.P.M=9R/MIN</h4>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img1} alt="jolly"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img2} alt="jolly"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">Jolly Big Two</h2>
                            <h4>Electrofreno</h4>
                            <h4>Corona 24cm</h4>
                            <h4>Para tubo 3 1/2"</h4>
                            <h4>Capacidad: 450kg / 28m2</h4>
                            <h4>Fuentes de alimentación: 110v-120v</h4>
                            <h4>Revoluciones por minuto: R.P.M=8R/MIN</h4>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="my-5 overflow-hidden">
                <div className=" container text-center" data-aos="zoom-in">
                    <h2 className="etitulo alinearletraselevador m-0 text-uppercase">accesorios</h2>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img3} alt="rib"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">TARJERA RIB</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">CO​NTROL RIB</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img4} alt="rib"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img5} alt="jielong"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">BOTONERA ​DE ​LLAVÍN</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">BOTONERA  ​DE ​PARED</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img6} alt="rib"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img7} alt="rib"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">ELECTROFENO</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">LIMITES</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img8} alt="rib"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img9} alt="rib"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">FOTOCELDAS ​RIB SLIM</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>



            <CotizaOption title={'¡Cotiza hoy mismo!'}/>
        </>
    );
};

export default MotoresRib;
