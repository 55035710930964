/******************************* Images Carousel Home *********************************/
import carouselHome1 from '../img/carouselHome/1.png';
import carouselHome2 from '../img/carouselHome/2.png';
import carouselHome3 from '../img/carouselHome/21.png';
import carouselHome4 from '../img/carouselHome/3.png';
/*import carouselHome5 from '../img/carouselHome/5.png';
import carouselHome6 from '../img/carouselHome/6.png';
import carouselHome7 from '../img/carouselHome/7.png';
import carouselHome8 from '../img/carouselHome/8.png';*/

const imgCarouselHome = [
    [
        carouselHome1,
        "Pimer portada",

    ],
    [
        carouselHome2,
        "Segunda portada",
    ],
    [
        carouselHome3,
        "Tercera portada",
    ],
    [
        carouselHome4,
        "Cuarta portada",

    ],
    /*[
        carouselHome5,
        "Quinta portada",
        "Some representative placeholder content for the cinco slide."
    ],
    [
        carouselHome6,
        "Sexta portada",
        "Some representative placeholder content for the seis slide."
    ],
    [
        carouselHome7,
        "Septima portada",
        "Some representative placeholder content for the siete slide."
    ],
    [
        carouselHome8,
        "Octava portada",
        "Some representative placeholder content for the ocho slide."
    ],*/
]

export { imgCarouselHome }