import img1 from "../img/productos/mecanismos_percianas/61.png";
import img2 from "../img/productos/mecanismos_percianas/62.png";
import img3 from "../img/productos/mecanismos_percianas/63.png";
import img4 from "../img/productos/mecanismos_percianas/64.png";
import img5 from "../img/productos/mecanismos_percianas/65.png";
import img6 from "../img/productos/mecanismos_percianas/66.png";
import img7 from "../img/productos/mecanismos_percianas/67.png";
import img8 from "../img/productos/mecanismos_percianas/68.png";

import img9 from "../img/productos/mecanismos_percianas/228.png";
import img10 from "../img/productos/mecanismos_percianas/229.png";
import img11 from "../img/productos/mecanismos_percianas/230.png";
import img12 from "../img/productos/mecanismos_percianas/231.png";
import img13 from "../img/productos/mecanismos_percianas/232.png";
import img14 from "../img/productos/mecanismos_percianas/233.png";
import img15 from "../img/productos/mecanismos_percianas/234.png";
import img16 from "../img/productos/mecanismos_percianas/235.png";
import img17 from "../img/productos/mecanismos_percianas/69.png";
/*import img18 from "../img/productos/mecanismos_percianas/detalle1.png";
import img19 from "../img/productos/mecanismos_percianas/detalle2.png";
import img20 from "../img/productos/mecanismos_percianas/detalle3.png";*/
import portada from "../img/portadas/Engranajes.png";
import CotizaOption from "../hooks/CotizaOption";
import BtnCotiza from "../hooks/BtnCotizar";
import { useEffect } from "react";

const PercianaMetalica = ({ setOther }) => {
    useEffect(() => {
        setOther(false);
    }, [setOther]);

    return (
        <>
            <section className="mb-5 ">
                <figure>
                    <img src={portada} className=" w-100 h-100 " alt=" portada" />
                </figure>
            </section>
            <section className="mb-5 overflow-hidden">
                <div className="mt-4 container text-center" data-aos="zoom-in">
                    <h2 className="etitulo alinearletraselevador m-0 text-uppercase">Mecanismos para persiana Metálica</h2>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <h2 className="title-sections text-center">Mecanismos de Engranaje</h2>
                    <div>
                        <figure>
                            <img className="w-100 h-100" src={img1} alt="Mecanismos de engranaje" />
                        </figure>
                    </div>
                </div>
            </section>

            <section className="mb-5 overflow-hidden">
                <div className="container" data-aos="fade-right">
                    <h2 className="title-sections text-start">Mecanismo de engranaje 38 - ​0.585x0.38- cortina 25 mts2 aprox.</h2>
                    <div className="d-flex gap-3 flexWrap">
                        <div className="flex-fill">
                            <figure>
                                <img className="w-100 h-100" src={img2} alt="engranaje 38" />
                            </figure>
                        </div>
                        <div className="flex-fill">
                            <figure>
                                <img className="w-100 h-100" src={img3} alt="engranaje 38" />
                            </figure>
                        </div>
                    </div>
                    <div className="mb-1 d-flex">
                        <BtnCotiza/>
                    </div>
                </div>
            </section>

            <section className="mb-5 overflow-hidden">
                <div className="container" data-aos="fade-left">
                    <h2 className="title-sections text-end">Mecanismo de engranaje 45 ​-0.71x0.45-cortina 45mts2 aprox.</h2>
                    <div className="d-flex gap-3 flexWrap">
                        <div className="flex-fill">
                            <figure>
                                <img className="w-100 h-100" src={img4} alt="engranaje 45" />
                            </figure>
                        </div>
                        <div className="flex-fill">
                            <figure>
                                <img className="w-100 h-100" src={img5} alt="engranaje 45" />
                            </figure>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mb-1">
                        <BtnCotiza/>
                    </div>
                </div>
            </section>

            <section className="mb-5 overflow-hidden">
                <div className="container"  data-aos="fade-right">
                    <h2 className="title-sections text-start">Cadena eslabón galvanizado ​3/16(especialmente para mecanismo engranaje)</h2>
                    <div className="d-flex gap-3 justify-content-center flexWrap">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img7} alt="cadena" />
                            </figure>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img6} alt="cadena" />
                            </figure>
                        </div>
                    </div>
                    <div className="mb-1 d-flex">
                        <BtnCotiza/>
                    </div>
                </div>
            </section>



            <section className="my-5 overflow-hidden">
                <div className=" container text-center" data-aos="zoom-in">
                    <h2 className="etitulo alinearletraselevador m-0">Mecanismos de Bicicleta ​100% canadiense</h2>
                </div>
                <div className="container" data-aos="zoom-in">
                    <div className="d-flex gap-3 justify-content-center flexWrap">
                        <figure>
                            <img className="w-100 h-100" src={img8} alt="mecanismos bicicleta" />
                        </figure>
                        <figure>
                            <img className="w-50 h-50 telefono-w-100" src={img17} alt="mecanismos bicicleta" />
                        </figure>
                    </div>
                    <div className="d-flex justify-content-center mb-4">
                        <BtnCotiza/>
                    </div>
                </div>
            </section>

            <section className="mb-5 position-relative overflow-x-scroll">
                <div className="container" data-aos="zoom-in">
                    <div className="position-relative my-5">
                        <h2 className="etitulo text-uppercase text-center">medidas de mecanismos</h2>
                    </div>
                    <div className="container-sizes mb-5">
                        <div className="pequeño">
                            <div className="title-pequeño">
                                <h3 className="text-uppercase">Mecanismos Bicicleta Pequeño</h3>
                            </div>
                            <div className="description-p1">
                                <h4>Accesorios completos (sproket 36b60, cadena bicicleta, cadena eslabón, polea-​base)</h4>
                            </div>
                            <div className="description-p2">
                                <h4>Para una cortina 225 lbs aprox</h4>
                            </div>
                            <div className="description-p3">
                                <h4>Cadena eslabón 8 mts- para una cortina de 4 mts.</h4>
                            </div>
                        </div>
                        <div className="mediano">
                            <div className="title-mediano">
                                <h3 className="text-uppercase">Mecanismos Bicicleta Mediano</h3>
                            </div>
                            <div className="description-m1">
                                <h4>Accesorios completos (sproket 41b60, cadena bicicleta, cadena eslabón, polea-​base)</h4>
                            </div>
                            <div className="description-m2">
                                <h4>Para una cortina 360 lbs aprox</h4>
                            </div>
                            <div className="description-m3">
                                <h4>Cadena eslabón 8 mts- para una cortina de 4 mts.</h4>
                            </div>
                        </div>
                        <div className="grande">
                            <div className="title-grande">
                                <h3 className="text-uppercase">Mecanismos Bicicleta Grande</h3>
                            </div>
                            <div className="description-g1">
                                <h4>Accesorios completos (sproket 50b54, cadena bicicleta, cadena eslabón, polea-​base)</h4>
                            </div>
                            <div className="description-g2">
                                <h4>Para una cortina 450 lbs aprox</h4>
                            </div>
                            <div className="description-g3">
                                <h4>Cadena eslabón 8 mts- para una cortina de 4 mts.</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5 overflow-hidden">
                <div className=" container text-center" data-aos="zoom-in">
                    <h2 className="etitulo alinearletraselevador m-0 text-uppercase">accesorios para cortinas de mecanismo</h2>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Resorte 9/32x1.10mt ​levanta 12 M2</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img9} alt="Resorte 9/32x1.10mt ​levanta 12 M2"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img10} alt="Resorte 5/16x1.10mt ​levanta 15 M2"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Resorte 5/16x1.10mt ​levanta 15 M2</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Bombo de 20X4</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img11} alt="Bombo de 20X4"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img12} alt="Bombitos con Cojinete"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Bombitos con Cojinete</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Bombitos sin Cojinete</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img13} alt="Bombitos sin Cojinete"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5 overflow-hidden">
                <div className="container">
                    <div className="d-flex justify-content-start mb-movil-5" data-aos="fade-left">
                        <div>
                            <figure className="d-flex justify-content-start mb-0">
                                <img src={img14} alt="Plato para mecanismo" className="w-100 h-100"/>
                            </figure>
                            <div>
                                <h2 className="title-sections m-0">Plato de 3 16X0.38 para Mecanismo</h2>
                                <h2 className="title-sections m-0">Plato de 3 16X0.45 para Mecanismo</h2>
                            </div>
                            <div className="d-flex mt-2 justify-content-start">
                                <BtnCotiza/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5 overflow-hidden">
                <div className="container">
                    <div className="d-flex justify-content-end mb-movil-5" data-aos="fade-right">
                        <div>
                            <figure className="d-flex justify-content-end mb-0">
                                <img src={img15} alt="Flecha" className="w-100 h-100"/>
                            </figure>
                            <div className="d-flex justify-content-end">
                                <h2 className="title-sections m-0">Flecha para Mecanismos de Bicicleta</h2>
                            </div>
                            <div className="d-flex mt-2 justify-content-end">
                                <BtnCotiza/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5 overflow-hidden">
                <div className="container mb-5">
                    <div className="d-flex justify-content-start mb-movil-5" data-aos="fade-left">
                        <div>
                            <figure className="d-flex justify-content-start mb-0">
                                <img src={img16} alt="Eje" className="w-100 h-100"/>
                            </figure>
                            <div>
                                <h2 className="title-sections m-0">Eje con tubo 4 Pulgadas con Resortes ​internos para Cortinas de Mecanismos</h2>
                            </div>
                            <div className="d-flex mt-2 justify-content-start">
                                <BtnCotiza/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <CotizaOption title={'¡Cotiza hoy mismo!'}/>
        </>
    );
};

export default PercianaMetalica;
