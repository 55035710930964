//import { IoMdArrowDropright } from "react-icons/io";
import { NavLink } from "react-router-dom";

const CotizaOption = ({title}) => {

    return (
        <section className='pidecotiza p-3 upcblue overflow-hidden'>
            <div data-aos="zoom-in">
                <p className="text-center textopie2 mb-0"> {title} </p>
                <p className="text-center textopie2">
                    <NavLink to="https://wa.me/+50230478057" rel="noopener noreferrer nofollow" target="_blank">
                        <span> 3047-8057</span>
                    </NavLink>
                </p>
            </div>
        </section>
    )
}
export default CotizaOption