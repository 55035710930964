import img3 from "../img/productos/elevadores/Elevador_2.png";
import img4 from "../img/productos/elevadores/Elevador_3.png";
import img1 from "../img/productos/elevadores/Elevador.png"
import img2 from "../img/productos/elevadores/17.png"
import portada from "../img/portadas/Elevadores.png"
import CotizaOption from "../hooks/CotizaOption";
import BtnCotiza from "../hooks/BtnCotizar";
import { useEffect } from "react";

const Elevadores = ({ setOther }) => {

    useEffect(() => {
        setOther(false);
    }, [setOther]);


    return (
        <>

            <section className="mb-5 ">

                <figure>
                    <img src={portada} className=" w-100 h-100 " alt=" portada" />

                </figure>

            </section>
            <section className="mb-5 overflow-hidden">
                <div className=" container text-center" data-aos="zoom-in">
                    <h2 className="etitulo alinearletraselevador my-3 text-uppercase">ELEVADORES DE PARQUEO</h2>
                </div>
            </section>
            <section className="mb-5 overflow-hidden">
                <div className="container d-flex gap-3 telefono ling-elevador da-ancho" data-aos="fade-right">
                    <div className="w-50 telefono-w-100">
                        <figure className="w-100 h-100">
                            <img
                                className="w-100 h-100"
                                src={img1}
                                alt="Cortinas Métalicas"
                            />
                        </figure>
                    </div>
                    <div className="w-50 d-flex align-items-center telefono-w-100">
                        <div>
                            <p className=" elevadoresp">
                                Se ofrecen 2 espacios de ​estacionamiento en un ​espacio existente,
                                para ​colocar el automóvil en la ​parte superior,
                                el automóvil ​inferior debe salir primero.
                            </p>
                            <p className="elevadoresp">
                                Los sistemas de ​estacionamiento de Mutrade ​facilitan a los usuarios
                                la ​optimización sencilla del ​espacio
                                de estacionamiento a ​través de una solución simple.
                            </p>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5 overflow-hidden">
                <div className="container d-flex gap-3 flexWrap" data-aos="fade-left">
                    <div className="w-50 telefono-w-100">
                        <figure>
                            <img
                                className=" w-100 h-100"
                                src={img3}
                                alt="elevador"
                            />
                        </figure>
                    </div>
                    <div className="w-50 telefono-w-100">
                        <figure>
                            <img
                                className=" w-100 h-100"
                                src={img4}
                                alt="elevador"
                            />
                        </figure>
                    </div>
                </div>
            </section>

            <section className="mb-5 overflow-hidden">
                <div className="container telefono spacepie" data-aos="fade-right">
                    <figure>
                        <img
                            className=" w-100 h-100"
                            src={img2}
                            alt="elevador"
                        />
                    </figure>
                </div>
            </section>
            
            <CotizaOption title={'¡Cotiza hoy mismo!'}/>
        </>



    );


};

export default Elevadores