import img1 from "../img/productos/duelas/accesorios/2.png";
import img2 from "../img/productos/duelas/accesorios/2.png";
import img3 from "../img/productos/duelas/accesorios/3.png";
import img4 from "../img/productos/duelas/accesorios/4.png";
import img5 from "../img/productos/duelas/accesorios/5.png";
import img6 from "../img/productos/duelas/accesorios/6.png";
import img7 from "../img/productos/duelas/accesorios/7.png";
import img8 from "../img/productos/duelas/accesorios/8.png";
import img10 from "../img/productos/duelas/accesorios/10.png";
import CotizaOption from "../hooks/CotizaOption";
import BtnCotiza from "../hooks/BtnCotizar";
import { useEffect } from "react";


const DuelasAluminioAccesorios = ({ setOther }) => {
    useEffect(() => {
        setOther(false);
    }, [setOther]);

    return (
        <>
            <section className="my-5 overflow-hidden">
                <div className=" container text-center" data-aos="zoom-in">
                    <h2 className="etitulo alinearletraselevador m-0 text-uppercase">accesorios de duelas de aluminio</h2>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img1} alt="Duela Galvalume o Aluzinc"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="title-sections">Duelas de aluminio</h2>
                            <h2 className="fw-bold title-sections">Medidas 5.80 de largo</h2>
                            <h4>
                                Duela de aluminio color blanco ​con relleno de polipropileno de ​5.5 cm
                            </h4>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="title-sections">Duelas de aluminio</h2>
                            <h2 className="fw-bold title-sections">Medidas 5.80 de largo</h2>
                            <h4>
                                Duela de aluminio color blanco ​con relleno de polipropileno de ​7.7 cm
                            </h4>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img2} alt="Duela Galvanizado"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img3} alt="Faldón"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Faldón</h2>
                            <h4>
                                Faldón de aluminio color ​blanco  de 5.5 cm
                            </h4>
                            <h4>
                                Faldón de aluminio color ​blanco de 7.7 cm
                            </h4>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Platos</h2>
                            <h4>
                                Platos de aluminio color ​blanco de 25*25
                            </h4>
                            <h4>
                                Platos de aluminio color ​blanco de 30*30
                            </h4>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img4} alt="Platos"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img5} alt="Guías"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Guías</h2>
                            <h4>
                                Guía de aluminio color blanco de ​5.5cm
                            </h4>
                            <h4>
                                Guías de aluminio color blanco ​7.7 cm
                            </h4>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="title-sections">Eje</h2>
                            <h2 className="fw-bold title-sections">Medidas 5.80 de largo</h2>
                            <h4>
                                Tubo octagonal de 60mm de ​aluminio color gris
                            </h4>
                            <h4>
                                Tubo octagonal de 70mm de ​aluminio color gris
                            </h4>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img6} alt="Eje"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img7} alt="Antai levante"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Antai Levante</h2>
                            <h4>
                                Sujetador de duela a eje para ​duela de 5.5 cm
                            </h4>
                            <h4>
                                Sujetador de duela a eje para ​duela de 7.7 cm
                            </h4>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">End Cap para Eje</h2>
                            <h4>
                                Adaptador para eje de 60mm y ​70mm
                            </h4>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img8} alt="End Cap para eje"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img10} alt="Clip para Duela"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Clip para Duela</h2>
                            <h4>
                                Seguros para duelas
                            </h4>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <CotizaOption title={'¡Cotiza hoy mismo!'}/>
        </>
    );
};

export default DuelasAluminioAccesorios;
