//import portada from "../img/productos/cortinas/accesorios/portada.png";
import img1 from "../img/productos/cortinas/accesorios/27.png";
import img2 from "../img/productos/cortinas/accesorios/24.png";
import img3 from "../img/productos/cortinas/accesorios/25.png";
import img4 from "../img/productos/cortinas/accesorios/23.png";
import img5 from "../img/productos/cortinas/accesorios/21.png";
import img6 from "../img/productos/cortinas/accesorios/22.png";
import img7 from "../img/productos/cortinas/accesorios/28.png";
import img8 from "../img/productos/cortinas/accesorios/241.png";
import img9 from "../img/productos/cortinas/accesorios/251.png";
import img10 from "../img/productos/cortinas/accesorios/261.png";
import img11 from "../img/productos/cortinas/accesorios/271.png";
import img12 from "../img/productos/cortinas/accesorios/281.png";
import img13 from "../img/productos/cortinas/accesorios/29.png";
import img14 from "../img/productos/cortinas/accesorios/30.png";
import img15 from "../img/productos/cortinas/accesorios/31.png";
import img16 from "../img/productos/cortinas/accesorios/32.png";
import img17 from "../img/productos/cortinas/accesorios/222.png";
import CotizaOption from "../hooks/CotizaOption";
import BtnCotiza from "../hooks/BtnCotizar";
import { useEffect } from "react";

const CortinasAccesorios = ({ setOther }) => {

    useEffect(() => {
        setOther(false);
    }, [setOther]);


    return (
        <>
            <section className="my-5 container d-flex justify-content-center">
                <h2 className="text-uppercase texttitulo text-center w-75 telefono-w-100">accesorios para cortinas metálicas</h2>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-left">
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="texto_tittfica">Tambores</h2>
                            <p className="cortinasp m-0">¡Tambor especial para cortinas metálicas ​enrollables disponible para ti, amigo ​distribuidor y mayorista!</p>
                            <p className="cortinasp m-0">
                                TAMBORES 20X11/4, 20X11/2, 20X2, 24x2
                            </p>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img src={img2}  className="w-100 h-100" alt="Tambores para cortinas" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div>
                            <figure>
                                <img src={img12}  className="w-100 h-100" alt="Faldones para cortinas" />
                            </figure>
                        </div>
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="texto_tittfica">Eje con tambor</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-left">
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="texto_tittfica">Gancho para Tambor</h2>
                            <p className="cortinasp">Nuestras ganchos aseguran la ​resistencia y durabilidad de tu cortina ​metálica durante muchos años.</p>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img src={img4}  className="w-100 h-100" alt="Gancho para cortinas" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div>
                            <figure>
                                <img src={img6}  className="w-100 h-100" alt="Muelle" />
                            </figure>
                        </div>
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="texto_tittfica">Muelle</h2>
                            <p className="cortinasp">4512, 5012, 5013, 5014, ​6012, 6013, 6014</p>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrap " data-aos="fade-left">
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="texto_tittfica">Jalador Galvanizado para Faldón</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img5} alt="Jaldor Galvanizado" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrap " data-aos="fade-right">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img9} alt="Jalador para cortinas" />
                            </figure>
                        </div>
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="texto_tittfica">Jalador para Cortina</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrapReverse " data-aos="fade-left">
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="texto_tittfica">Resortes</h2>
                            <p className="cortinasp m-0">
                                Resortes de alta calidad para ​cortinas metálicas enrollables, ​ideales para tus instalaciones o ​clientes.
                            </p>
                            <p className="cortinasp m-0">
                                RESORTE 7/32X0.30 (levanta 4M2)
                            </p>
                            <p className="cortinasp m-0">
                                RESORTE 3/16X0.30 (levanta 3M2)
                            </p>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img1} alt="Resortes para cortinas" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrap " data-aos="fade-right">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img17} alt="Bombos para tubo" />
                            </figure>
                        </div>
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="texto_tittfica">Bombos para tubo</h2>
                            <h2>
                                Medidas
                            </h2>
                            <p className="cortinasp m-0">
                                1 " 11/4" 11/2" Y 2"
                            </p>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrap " data-aos="fade-left">
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="texto_tittfica">Guías</h2>
                            <h2>Medidas</h2>
                            <p className="cortinasp">
                                La función de las guías es guiar el ​desplazamiento vertical del lienzo ​para abrir y cerrar la cortina ​metálica.
                            </p>
                            <p className="cortinasp m-0">
                                 1/16X0.05X2.44
                            </p>
                            <p className="cortinasp m-0">
                                 1/16X0.07X2.44
                            </p>
                            <p className="cortinasp m-0">
                                 3/32X0.05X2.44
                            </p>
                            <p className="cortinasp m-0">
                                 3/32X0.07X2.44
                            </p>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img3} alt="Guías para cortinas" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrap " data-aos="fade-right">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img7} alt="Platos para Guías" />
                            </figure>
                        </div>
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="texto_tittfica">Platos para Guías</h2>
                            <h2>Medidas</h2>
                            <p className="cortinasp m-0">
                                 0.30X0.30X3/32
                            </p>
                            <p className="cortinasp m-0">
                                 0.33X0.33X3/32
                            </p>
                            <p className="cortinasp m-0">
                                 0.35X0.35X3/32
                            </p>
                            <p className="cortinasp m-0">
                                 0.38X0.38X3/32
                            </p>
                            <p className="cortinasp m-0">
                                 0.40X0.40X3/32
                            </p>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-left">
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="texto_tittfica">Pasadores</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img src={img8}  className="w-100 h-100" alt="Pasadores" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div>
                            <figure>
                                <img src={img10}  className="w-100 h-100" alt="Chapetas" />
                            </figure>
                        </div>
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="texto_tittfica">Chapetas</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="daralinicacio container d-flex justify-content-center">
                <h2 className="text-uppercase texttitulo text-center">eje para cortinas normales</h2>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrap " data-aos="fade-left">
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="texto_tittfica">Eje con resortes</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img11} alt="Eje con resortes" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="daralinicacio container d-flex justify-content-center">
                <h2 className="text-uppercase texttitulo text-center">faldones para cortinas</h2>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrap " data-aos="fade-right">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img13} alt="Faldones para corinas" />
                            </figure>
                        </div>
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="texto_tittfica">Faldones para Cortinas</h2>
                            <p className="cortinasp m-0">
                                FALDON VOLTEADO
                            </p>
                            <p className="cortinasp m-0">
                                FALDON CON VITRINA
                            </p>
                            <p className="cortinasp m-0">
                                FALDON CON SISTEMA DE SEGURIDAD
                            </p>
                            <p className="cortinasp m-0">
                                FALDON NORMAL CON SUS PASADORES
                            </p>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-left">
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="texto_tittfica">Chapa Yale para ​Cortinas Metálicas</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img src={img14}  className="w-100 h-100" alt="Chapa Yale para ​Cortinas Metálicas" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrap " data-aos="fade-right">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img15} alt="Chapa Yale para puerta" />
                            </figure>
                        </div>
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="texto_tittfica">Chapa Yale para puerta</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-left">
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="texto_tittfica">Puerta Peatonal</h2>
                            <p className="cortinasp m-0">
                                Medida estándar
                            </p>
                            <p className="cortinasp m-0">
                                0.55cm x 1.50mt
                            </p>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img src={img16}  className="w-100 h-100" alt="Puerta Peatonal" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <CotizaOption title={'¡Cotiza hoy mismo!'}/>
        </>
    );


};

export default CortinasAccesorios