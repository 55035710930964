import { useEffect } from "react"
import portada from "../img/productos/cortina_hawaina/portada.png";
import img1 from "../img/productos/cortina_hawaina/237.png";
import img2 from "../img/productos/cortina_hawaina/238.png";
import img3 from "../img/productos/cortina_hawaina/239.png";
import img4 from "../img/productos/cortina_hawaina/240.png";
import video from "../img/productos/cortina_hawaina/cortina_hawaina.mp4";
import CotizaOption from "../hooks/CotizaOption";
import BtnCotiza from "../hooks/BtnCotizar";
import { GoDotFill } from "react-icons/go";

const Hawaina = ({ setOther }) => {

    useEffect(() => {
        setOther(true)
    }, [setOther])

    return (
        <>
            <section className="mb-5">
                <figure>
                <img src={portada} className=" w-100 h-100 " alt=" portada" />
                </figure>
            </section>

            <section className="mb-5 container d-flex justify-content-center">
                <h2 className="text-uppercase texttitulo text-center w-75 telefono-w-100">cortina hawaina para cuartos fríos</h2>
            </section>

            <section className="mb-5 overflow-hidden">
                <div className="container d-flex gap-3 align-items-center flexWrap" data-aos="fade-right">
                    <div >
                        <video poster={portada} className="w-100 h-100 object-fit-cover video-init" autoPlay loop muted>
                            <source src={video} type="video/mp4" />
                        </video>
                    </div>
                    <div>
                        <div className="mb-2">
                            <p className="mb-0">
                                Cortinas hawainas de fácil instalación y mantenimiento, aplicables a almacenes, laboratorios, fábricas, cámaras refrigeradas, entre otros.
                            </p>
                            <p className="mb-0">
                                Proporcionan una barrera flexible y ayuda a aislar condiciones no deseadas de polvo, ruido, insectos, etc. Ahorro de energía en las áreas de aplicación.
                            </p>
                        </div>
                        <div className="d-flex gap-1 align-items-center">
                            <span>
                                <GoDotFill size={10} />
                            </span>
                            <p className="mb-0">Se utilizan indistintamente en interiores y exteriores.</p>
                        </div>
                        <div className="d-flex gap-1 align-items-center mb-3">
                            <span>
                                <GoDotFill size={10} />
                            </span>
                            <p className="mb-0">Ayudan a mantener la temperatura evitando fugas de aire frío y caliente.</p>
                        </div>
                        <div className="d-flex">
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5 overflow-hidden">
                <div className="container  d-flex gap-3 flexWrapReverse" data-aos="fade-left">
                    <div className="w-50 d-flex align-items-center telefono-w-100">
                        <div>
                            <h2 className='title-sections'>
                                ROLLO DE PVC
                            </h2>
                            <h4 className="title-sections">
                                Azul claro transparente
                            </h4>
                            <p className="mb-0">
                                Espesor 2MM
                            </p>
                            <p className="mb-0">
                                Ancho 30CM
                            </p>
                            <p>
                                Longitud de cada rollo 50 Metros
                            </p>
                            <div className="d-flex">
                                <BtnCotiza/>
                            </div>
                        </div>
                    </div>
                    <div className="imagen_terjeta">
                        <figure>
                            <img
                                className="w-100 h-100" src={img1} alt="Rollos"
                            />
                        </figure>
                    </div>
                </div>
            </section>

            <section className="mb-5 overflow-hidden">
                <div className="container" data-aos="zoom-out">
                    <div className="d-flex gap-3 flexWrap">
                        <div className="w-50 telefono-w-100">
                            <figure>
                                <img  className="w-100 h-100" src={img2} alt="Rollos"/>
                            </figure>
                        </div>
                        <div className="w-50 telefono-w-100 d-flex flex-column justify-content-center">
                            <h2 className="text-uppercase text-center title-sections">Anclaje de pvc</h2>
                            <p>
                                Soporte PVC ideal para colgar las tiras de plástico para la cortina hawaina de manera fácil y rápida.
                            </p>
                            <div className="d-flex mb-3">
                                <BtnCotiza/>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex gap-3 flexWrap">
                        <div className="w-50 telefono-w-100">
                            <figure>
                                <img  className="w-100 h-100" src={img4} alt="Rollos"/>
                            </figure>
                        </div>
                        <div className="w-50 telefono-w-100">
                            <figure>
                                <img  className="w-100 h-100" src={img3} alt="Rollos"/>
                            </figure>
                        </div>
                    </div>
                    <div>

                    </div>
                </div>
            </section>

            <CotizaOption title={'¡Cotiza hoy mismo!'}/>
        </>
    )
}

export default Hawaina