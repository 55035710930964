import retractil from "../img/productos/toldos/retractil.png"
import portada from "../img/productos/toldos/portada.png";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import img1 from "../img/productos/toldos/83.png";
import img2 from "../img/productos/toldos/84.png";
import img3 from "../img/productos/toldos/1.png";
import img4 from "../img/productos/toldos/2.png";
import img5 from "../img/productos/toldos/3.png";
import img6 from "../img/productos/toldos/4.png";
import img7 from "../img/productos/toldos/7.png";
import img8 from "../img/productos/toldos/6.png";
import img9 from "../img/productos/toldos/8.png";
import img10 from "../img/productos/toldos/9.png";
import video1 from "../img/productos/toldos/29.mp4";
import video2 from "../img/productos/toldos/28.mp4";
import CotizaOption from "../hooks/CotizaOption";
import BtnCotiza from "../hooks/BtnCotizar";
import { GoDotFill } from "react-icons/go";
import { useEffect, useRef } from "react";

const Toldos = ({ setOther }) => {

    const refManuales = useRef(null)
    const refMonitorizados = useRef(null)

    useEffect(() => {
        setOther(false);
    }, [setOther]);

    const scrollManuales = () => refManuales.current.scrollIntoView({ block: "end" })
    const scrollMonitorizados = () => refManuales.current.scrollIntoView({ block: "start" })  

    return (
        <>
            <section>
                <figure>
                    <img src={portada} className="w-100 h-100" alt="Portada Toldos Retráctiles"/>
                </figure>
            </section>

            <section className="daralinicacio container d-flex justify-content-center">
                <h2 className="text-uppercase texttitulo txt-movil text-center w-75 telefono-w-100">toldos retráctiles</h2>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrapReverse " data-aos="fade-right">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={retractil} alt="Toldos retráctiles" />
                            </figure>
                        </div>
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="texto_tittfica sbtxt-movil">Toldos retráctiles</h2>
                            <p className="cortinasp">
                                Es la elección ideal para terrazas en ​hogares y comerciales. Además de ​su versatilidad, un toldo retráctil ​ofrece protección contra el sol, el ​calor, los rayos ultravioleta y mejora ​la apariencia exterior del lugar.
                            </p>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex flex-column" data-aos="zoom-in">
                        <div className="mb-3">
                            <h2 className="texttitulo text-center">Beneficios</h2>
                        </div>
                        <div className="d-flex flex-column">
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoCheckmarkCircleSharp size={30} />
                                </span>
                                <span className="cortinasp">Tener la opción de disfrutar del sol o buscar la sombra.</span>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoCheckmarkCircleSharp size={30} />
                                </span>
                                <span className="cortinasp">Protege tu piel de los dañinos rayos solares.</span>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoCheckmarkCircleSharp size={30} />
                                </span>
                                <span className="cortinasp">Variedad de estilos y tamaños para seleccionar.</span>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoCheckmarkCircleSharp size={30} />
                                </span>
                                <span className="cortinasp">Larga durabilidad.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex flex-column" data-aos="zoom-in">
                        <div className="mb-3">
                            <h2 className="texttitulo text-center">Medidas Disponibles</h2>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <GoDotFill size={30} />
                                </span>
                                <span className="cortinasp">2.00x1.50 metros</span>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <GoDotFill size={30} />
                                </span>
                                <span className="cortinasp">3.00x2.00 metros</span>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <GoDotFill size={30} />
                                </span>
                                <span className="cortinasp">4.00x2.50 metros</span>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <GoDotFill size={30} />
                                </span>
                                <span className="cortinasp">5.00x3.00 metros</span>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <GoDotFill size={30} />
                                </span>
                                <span className="cortinasp">6.00x4.00 metros</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="daralinicacio overflow-hidden position-relative">
                <div className="position-absolute z-n1 enable-figure">
                    <figure>
                        <img src={img5} className="w-100 h-100" alt="manuales"/>
                    </figure>
                </div>
                <div className="position-absolute z-n1 end-0 figure-toldos">
                    <figure>
                        <img src={img6} className="w-100 h-100" alt="manuales"/>
                    </figure>
                </div>
                <div className="position-absolute z-n1 end-0 bottom-0 enable-figure">
                    <figure>
                        <img src={img7} className="w-100 h-100" alt="manuales"/>
                    </figure>
                </div>
                <div className="position-absolute z-n1 start-0 bottom-0 figure-toldos">
                    <figure>
                        <img src={img8} className="w-100 h-100" alt="manuales"/>
                    </figure>
                </div>
                <div>
                    <div className="d-flex justify-content-start img-manuales">
                        <figure onClick={scrollManuales} className="cursor-pointer">
                            <img src={img3} className="w-100 h-100" alt="manuales"/>
                        </figure>
                    </div>
                    <div className="d-flex justify-content-end img-motorizados">
                        <figure onClick={scrollMonitorizados} className="cursor-pointer">
                            <img src={img4} className="w-100 h-100" alt="motorizados"/>
                        </figure>
                    </div>
                </div>

            </section>

            <section ref={refManuales} className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrapReverse " data-aos="fade-right">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img1} alt="Toldos Manuales" />
                            </figure>
                        </div>
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="texto_tittfica sbtxt-movil text-uppercase">manuales</h2>
                            <p className="cortinasp">
                                Los toldos manuales son populares ​por su bajo costo y facilidad de ​instalación. Se manejan ​manualmente con una manivela ​para abrir y cerrarlos.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section ref={refMonitorizados} className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div className="d-flex flex-column justify-content-center w-50 telefono-w-100">
                            <h2 className="texto_tittfica sbtxt-movil text-uppercase">motorizados</h2>
                            <p className="cortinasp">Los toldos motorizados son una opción ​avanzada y tecnológica que se controla ​con un motor eléctrico para abrir y cerrar ​con facilidad.</p>
                        </div>
                        <div>
                            <figure>
                                <img src={img2}  className="w-100 h-100" alt="Toldos motorizados" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="daralinicacio overflow-hidden position-relative">
                <div data-aos="zoom-in">
                    <div className="container d-flex justify-content-center">
                        <p className="cortinasp sub-description-toldos">Los toldos retráctiles son estructuras versátiles y prácticas diseñadas para proteger del sol y proporcionar un ambiente agradable en áreas al aire libre.</p>
                    </div>
                    <div className="position-absolute z-n1 start-0 bottom-0 figure-toldos">
                        <figure className="mb-0">
                            <img src={img9} className="w-100 h-100" alt="manuales"/>
                        </figure>
                    </div>
                    <div className="position-absolute z-n1 end-0 bottom-0 figure-toldos figure-toldos-altura">
                        <figure className="mb-0">
                            <img src={img10} className="w-100 h-100" alt="manuales"/>
                        </figure>
                    </div>
                </div>
            </section>

            <section className="daralinicacio overflow-hidden">
                <div data-aos="zoom-out">
                    <div className="pt-5 color-bg">
                        <h2 className="text-uppercase texttitulo txt-movil text-center">toldos retráctiles</h2>
                    </div>
                    <div className="container d-flex content-movil-toldos-videos gap-5 mb-5 position-relative container-videos">
                        <div className="d-flex flex-column w-50">
                            <video poster={img1} className="w-100 h-100 object-fit-cover video-init" autoPlay loop muted>
                                <source src={video1} type="video/mp4" />
                            </video>
                            <h2 className="text-uppercase my-2 text-center">manuales</h2>
                        </div>
                        <div className="d-flex flex-column w-50">
                            <video poster={img2} className="w-100 h-100 object-fit-cover video-init" autoPlay loop muted>
                                <source src={video2} type="video/mp4" />
                            </video>
                            <h2 className="text-uppercase my-2 text-center">motorizados</h2>
                        </div>
                    </div>
                    <div className="py-3 backgroun-green-light mb-5"></div>
                </div>
            </section>

            <CotizaOption title={'¡Cotiza hoy mismo!'}/>
        </>
    );


};

export default Toldos