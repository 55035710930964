import img1 from "../img/productos/duelas/30.png";
import img2 from "../img/productos/duelas/46.png";
import img3 from "../img/productos/duelas/78.png";
import img4 from "../img/productos/duelas/49.png";
import img5 from "../img/productos/duelas/79.png";
import img6 from "../img/productos/duelas/50.png";
import img7 from "../img/productos/duelas/80.png";
import img8 from "../img/productos/duelas/81.png";
import img9 from "../img/productos/duelas/82.png";
import img10 from "../img/productos/duelas/84.png";
import img11 from "../img/productos/duelas/85.png";
import img12 from "../img/productos/duelas/55.png";
import img13 from "../img/productos/duelas/51.png";
import img14 from "../img/productos/duelas/56.png";
import img15 from "../img/productos/duelas/48.png";
import img16 from "../img/productos/duelas/52.png";
import img17 from "../img/productos/duelas/54.png";
import img18 from "../img/productos/duelas/53.png";
import img19 from "../img/productos/duelas/1.png";
import img20 from "../img/productos/duelas/2.png";
import img21 from "../img/productos/duelas/3.png";
import img22 from "../img/productos/duelas/4.png";
import img23 from "../img/productos/duelas/83.png"
import img24 from "../img/productos/duelas/madera1022.png";
import CotizaOption from "../hooks/CotizaOption";
import BtnCotiza from "../hooks/BtnCotizar";
import { useEffect } from "react";


const DuelasEstilos = ({ setOther }) => {
    useEffect(() => {
        setOther(false);
    }, [setOther]);

    return (
        <>
            <section className="my-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Duela Galvalume o Aluzinc de 10 cm, Calibre 21</h2>
                            <p>
                                El acero Galvalume se refiere a productos de acero recubiertos con 55% de Al-Zn, que también se denomina acero aluzinc.Debido a su versatilidad, durabilidad y hermosa apariencia, el acero galvalume es ampliamente preferido.
                            </p>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img1} alt="Duela Galvalume o Aluzinc de 10 cm, calibre 21"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img2} alt="Duela Galvanizado de 10 cm, calibre 21"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Duela Galvanizado de 10 cm, Calibre 21</h2>
                            <p>
                            Laminada en caliente o frío revestida en ambas caras con una capa de zinc por el proceso de inmersión en un baño de zinc fundido. Adquiere resistencia a la corrosión. Acabados brillante y matizado.
                            </p>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Duela Blanca Pintado al ​Horno de 10 cm, Calibre 22</h2>
                            <p>
                                Duela de acero galvanizado y recubrimiento de pintura al horno de fábrica de un solo lado, resistente a los rayones, golpes, y de alta durabilidad.
                            </p>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img3} alt="Duela Blanca Pintado al ​Horno de 10 cm, calibre 22"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img4} alt="Duela Azul Pintado al ​Horno de 10 cm, calibre 22"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Duela Azul Pintado al ​Horno de 10 cm, Calibre 22</h2>
                            <p>
                                Duela de acero galvanizado y recubrimiento de pintura al horno de fábrica de un solo lado, resistente a los rayones, golpes, y de alta durabilidad.
                            </p>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Duela Amarillo Negro  Pintado ​al Horno de 10 cm, Calibre 22</h2>
                            <p>
                                Duela de acero galvanizado y recubrimiento de pintura al horno de fábrica de un solo lado, resistente a los rayones, golpes, y de alta durabilidad.
                            </p>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img19} alt="Duela amarillo negro  Pintado ​al Horno de 10 cm, calibre 22"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img20} alt="Duela color negro  Pintado ​al Horno de 10 cm, calibre 22"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Duela color Negro  Pintado ​al Horno de 10 cm, Calibre 22</h2>
                            <p>
                                Duela de acero galvanizado y recubrimiento de pintura al horno de fábrica de un solo lado, resistente a los rayones, golpes, y de alta durabilidad.
                            </p>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Duela Color Madera Pintado al Horno de 10 cm, Calibre 22</h2>
                            <p>
                                Duela de acero galvanizado y recubrimiento de pintura al horno de fábrica de un solo lado, resistente a los rayones, golpes, y de alta durabilidad.
                            </p>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img24} alt="Duela Color Madera Pintado al Horno de 10 cm, Calibre 22"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img8} alt="Duela Galvalum Troquelada de 10 cm Calibre 21"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Duela Galvalum Troquelada de 10 cm Calibre 21</h2>
                            <p>
                                Duela galvalum en calibre 21 con perforaciones rectangulares de 8 cms x 2 cms. La cualidad de esta duela es permitir la entrada de aire cuando se requiera ventilación y además exhibir productos diversos con la misma seguridad de una cortina cerrada
                            </p>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Duela Galvalum con diseño ​10 cm de ancho</h2>
                            <p>
                                El acero Galvalume se refiere a productos de acero recubiertos con 55% de Al-Zn, que también se denomina acero aluzinc.Debido a su versatilidad, durabilidad y ​hermosa apariencia, el acero galvalum es ampliamente preferido.
                            </p>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img21} alt="Duela galvalum con diseño ​10 cm de ancho"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img10} alt="Duela Galvalume o Aluzinc de 6 cm, calibre 21"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Duela Galvalum o Aluzinc de 6 cm, Calibre 21</h2>
                            <p>
                                El acero Galvalume se refiere a productos de acero recubiertos con 55% de Al-Zn, que también se denomina acero aluzinc plano.
                            </p>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Duela Galvanizado de 6 cm, Calibre 21</h2>
                            <p>
                                Laminada en caliente o frío revestida en ambas caras con una capa de zinc por el proceso de inmersión en un baño de zinc fundido,adquiere resistencia a la corrosión acabados brillante y matizado.
                            </p>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img11} alt="Duela Galvanizado de 6 cm, calibre 21"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img5} alt="Duela Micro Perforada ​Galvanizadode 10 cm, calibre 22"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Duela Micro Perforada ​Galvanizadode 10 cm, Calibre 22</h2>
                            <p>
                            Duela de acero galvanizado calibre 22, de varias micro perforaciones, la cualidad de esta duela es que permite exhibir el productos diversos con la seguridad de una cortina cerrada.
                            </p>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Duela Micro Perforada Color ​Negro de 10 cm, Calibre 22</h2>
                            <p>
                                Duela pintado al horno negra calibre 22, de varias micro perforaciones, la cualidad de esta duela es que permite exhibir el productos diversos con la seguridad de una cortina cerrada.
                            </p>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img6} alt="Duela Micro Perforada Color ​Negro de 10 cm, calibre 22"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img7} alt="Duela Micro Perforada Color ​Blanco de 10cm, calibre 22"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Duela Micro Perforada Color ​Blanco de 10cm, Calibre 22</h2>
                            <p>
                                Duela pintado al horno blanco calibre 22, de varias micro perforaciones, la cualidad de esta duela es que permite exhibir el productos diversos con la seguridad de una cortina cerrada.
                            </p>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Duela Galvalum Troquelada de 6 cm Calibre 21</h2>
                            <p>
                            Duela galvalum en calibre 21 con perforaciones rectangulares de 8 cms x 2 cms. La cualidad de esta duela es permitir la entrada de aire cuando se requiera ventilación y además exhibir productos diversos con la misma seguridad de una cortina cerrada.
                            </p>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img22} alt="Duela Galvalum Troquelada de 6 cm Calibre 21"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img23} alt="Duela Micro galvalum de 10 cm, calibre 22"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Duela Micro Galvalum de 10 cm, Calibre 22</h2>
                            <p>
                                Duela de acero galvanizado calibre 22, de varias micro perforaciones, la cualidad de esta duela es que permite exhibir el productos diversos con la seguridad de una cortina cerrada
                            </p>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Duelas Perfil Plano de 6CM</h2>
                            <p>
                                Esta duela es más útil en centros comerciales por su fino ​acabado, recibe otro nombre como duela plana se maneja ​en varios colores, debido a que son más pequeñas cubre ​de manera más uniforme, dando una apariencia estética ​elegante y atractiva.
                            </p>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img9} alt="DUELAS PERFIL PLANO DE 6CM"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img12} alt="Duela Madera Oscuro Pintado ​al Horno de 6 cm, calibre 22"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Duela Madera Oscuro Pintado ​al Horno de 6 cm, Calibre 22</h2>
                            <p>
                                Duela de acero galvanizado y recubrimiento de pintura al horno de fábrica de un solo lado, resistente a los rayones, golpes, y de alta durabilidad
                            </p>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Duela Madera Caoba Pintado ​al Horno de 6 cm, Calibre 22</h2>
                            <p>
                                Duela de acero galvanizado y recubrimiento de pintura al horno de fábrica de un solo lado, resistente a los rayones, golpes, y de alta durabilidad.
                            </p>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img13} alt="Duela Madera Caoba Pintado ​al Horno de 6 cm, calibre 22"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img14} alt="Duela Blanca Pintado al ​Horno de 6 cm, calibre 22"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Duela Blanca Pintado al ​Horno de 6 cm, Calibre 22</h2>
                            <p>
                                Duela de acero galvanizado y recubrimiento de pintura al horno de fábrica de un solo lado, resistente a los rayones, golpes, y de alta durabilidad.
                            </p>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Duela Azul Pintado al Horno de 6 cm, Calibre 22</h2>
                            <p>
                                Duela de acero galvanizado y recubrimiento de pintura al horno de fábrica de un solo lado, resistente a los rayones, golpes, y de alta durabilidad.
                            </p>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img15} alt="Duela Azul Pintado al Horno de 6 cm, calibre 22"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img16} alt="Duela Micro Perforada ​Galvanizado de 6 cm, calibre 22"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Duela Micro Perforada ​Galvanizado de 6 cm, Calibre 22</h2>
                            <p>
                                Duela de acero galvanizado calibre 22, de varias micro perforaciones, la cualidad de esta duela es que permite exhibir el productos diversos con la seguridad de una cortina cerrada.
                            </p>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Duela Micro Perforada Color ​Negro de 6 cm, Calibre 22</h2>
                            <p>
                                Duela pintado al horno negra calibre 22, de varias micro perforaciones, la cualidad de esta duela es que permite exhibir el productos diversos con la seguridad de una cortina cerrada.
                            </p>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img17} alt="Duela Micro Perforada Color ​Negro de 6 cm, calibre 22"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img18} alt="Duela Micro Perforada Color ​Blanco de 6 cm, calibre 22"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Duela Micro Perforada Color ​Blanco de 6 cm, Calibre 22</h2>
                            <p>
                                Duela pintado al horno blanco calibre 22, de varias micro perforaciones, la cualidad de esta duela es que permite exhibir el productos diversos con la seguridad de una cortina cerrada.
                            </p>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            {/*<section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="fw-bold title-sections">Duela Galvalum Troquelada de 10 cm Calibre 21</h2>
                            <p>
                                Duela galvalum en calibre 21 con perforaciones rectangulares de 8 cms x 2 cms. La cualidad de esta duela es permitir la entrada de aire cuando se requiera ventilación y además exhibir productos diversos con la misma seguridad de una cortina cerrada
                            </p>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img19} alt="Duela Galvalum Troquelada de 10 cm Calibre 21"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>*/}

            

            <CotizaOption title={'¡Cotiza hoy mismo!'}/>
        </>
    );
};

export default DuelasEstilos;
