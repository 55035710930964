import img1 from "../img/productos/motores/interior_exterior/41.png";
import img2 from "../img/productos/motores/interior_exterior/69.png";
import img3 from "../img/productos/motores/interior_exterior/70.png";
import img4 from "../img/productos/motores/interior_exterior/accesorios/44.png";
import img5 from "../img/productos/motores/interior_exterior/accesorios/43.png";
import img6 from "../img/productos/motores/interior_exterior/accesorios/73.png";
import img7 from "../img/productos/motores/interior_exterior/accesorios/74.png";
import img8 from "../img/productos/motores/interior_exterior/accesorios/75.png";
import img9 from "../img/productos/motores/interior_exterior/accesorios/76.png";
import img10 from "../img/productos/motores/interior_exterior/accesorios/58.png";
import img11 from "../img/productos/motores/interior_exterior/accesorios/39.png";
import img12 from "../img/productos/motores/interior_exterior/accesorios/48.png";
import img13 from "../img/productos/motores/interior_exterior/accesorios/40.png";
import { GoDotFill } from "react-icons/go";
import BtnCotiza from "../hooks/BtnCotizar";
import CotizaOption from "../hooks/CotizaOption";
import { useEffect } from "react";


const MotoresTubulares = ({ setOther }) => {
    useEffect(() => {
        setOther(false);
    }, [setOther]);

    return (
        <>
            <section className="my-5 overflow-hidden">
                <div className=" container text-center" data-aos="zoom-in">
                    <h2 className="etitulo alinearletraselevador m-0 text-uppercase">motores tubulares</h2>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img1} alt="tubular"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">MOTOR ​INALÁMBRICO DE ​2 NW</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">MOTOR DE 50 NW</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img2} alt="tubular"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img3} alt="tubular"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">motores de</h2>
                            <div className="d-flex gap-1 align-items-center title-sections">
                                <span>
                                    <GoDotFill size={20} />
                                </span>
                                <h3>59x80 NW</h3>
                            </div>
                            <div className="d-flex gap-1 align-items-center title-sections">
                                <span>
                                    <GoDotFill size={20} />
                                </span>
                                <h3>59x100 NW</h3>
                            </div>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5 overflow-hidden">
                <div className=" container text-center" data-aos="zoom-in">
                    <h2 className="etitulo alinearletraselevador m-0 text-uppercase">accesorios</h2>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">CONTROL ​1 CANAL</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img4} alt="tubular"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img5} alt="tubular"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">CONTROL ​MULTICANAL</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">Tarjeta</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img6} alt="tubular"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img7} alt="tubular"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">Control</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">WI-FI BRIDGED ​D7002B</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img8} alt="tubular"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img9} alt="tubular"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">CARGADOR</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">SENSOR</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img10} alt="tubular"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="my-5 overflow-hidden">
                <div className=" container text-center" data-aos="zoom-in">
                    <h2 className="etitulo alinearletraselevador m-0 text-uppercase">motores tubulares nice</h2>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img11} alt="tubular"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">motores nice</h2>
                            <div className="d-flex gap-1 align-items-center title-sections">
                                <h3>15 NW</h3>
                            </div>
                            <div className="d-flex gap-1 align-items-center title-sections">
                                <h3>30 NW</h3>
                            </div>
                            <div className="d-flex gap-1 align-items-center title-sections">
                                <h3>50 NW</h3>
                            </div>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="my-5 overflow-hidden">
                <div className=" container text-center" data-aos="zoom-in">
                    <h2 className="etitulo alinearletraselevador m-0 text-uppercase">accesorios</h2>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img12} alt="tubular"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">sensor nice</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">control</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img13} alt="tubular"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>



            <CotizaOption title={'¡Cotiza hoy mismo!'}/>
        </>
    );
};

export default MotoresTubulares;
