import portada from "../img/productos/telas/portada.png";
import { imgCarouselTelas2 } from "../helper/ImagesTelas";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import CotizaOption from "../hooks/CotizaOption";
import { GoDotFill } from "react-icons/go";
import { useEffect } from "react";
import img1 from "../img/productos/telas/10.png";
import img2 from "../img/productos/telas/11.png";
import img3 from "../img/productos/telas/12.png";
import img4 from "../img/productos/telas/13.png";
import img5 from "../img/productos/telas/32.png";
import img6 from "../img/productos/telas/33.png";
import img8 from "../img/productos/telas/31.png";
import img9 from "../img/productos/telas/18.png";
import BtnCotiza from "../hooks/BtnCotizar";

const Telas = ({ setOther }) => {

    useEffect(() => {
        setOther(false);
    }, [setOther]);


    return (
        <>
            <section className="mb-5">
                <figure>
                    <img src={portada} className="w-100 h-100" alt="Telas Acrílicas"/>
                </figure>
            </section>

            <section className="mb-5 container d-flex justify-content-center container-movil">
                <h2 className="text-uppercase texttitulo text-center w-75 telefono-w-100 title-telas-movil">telas para sombras</h2>
            </section>

            <section className="mb-5 overflow-hidden container-movil">
                <div className="container">
                    <div className="d-flex justify-content-center" data-aos="zoom-in">
                        <div className="d-flex flex-column">
                            <div className="d-flex gap-1 align-items-center">
                                <span className="title-sections">
                                    <GoDotFill size={30} />
                                </span>
                                <span className="texto_tittfica description-telas-movil">Telas para toldo</span>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="title-sections">
                                    <GoDotFill size={30} />
                                </span>
                                <span className="texto_tittfica description-telas-movil">Telas para sombrilla</span>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="title-sections">
                                    <GoDotFill size={30} />
                                </span>
                                <span className="texto_tittfica description-telas-movil">Telas para sombra</span>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="title-sections">
                                    <GoDotFill size={30} />
                                </span>
                                <span className="texto_tittfica description-telas-movil">Telas para rollers</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5 overflow-hidden">
                <div className="container" data-aos="flip-left">
                    <h2 className="text-uppercase texttitulo text-center telefono-w-100 title-telas-movil mb-5">telas saran</h2>
                    <div className="d-flex justify-content-around gap-5 container-movil-telas">
                        <div>
                            <figure>
                                <img src={img1} alt="Color verde" className="w-100 h-100"/>
                            </figure>
                            <p className="m-0 text-center py-2 text-white bg-blue">Color Verde</p>
                        </div>
                        <div>
                            <figure>
                                <img src={img2} alt="Color gris" className="w-100 h-100"/>
                            </figure>
                            <p className="m-0 text-center py-2 text-white bg-blue">Color Gris</p>
                        </div>
                        <div>
                            <figure>
                                <img src={img3} alt="Color beige" className="w-100 h-100"/>
                            </figure>
                            <p className="m-0 text-center py-2 text-white bg-blue">Color Beige</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5 overflow-hidden">
                <div className="container">
                    <h2 className="text-uppercase texttitulo text-center telefono-w-100 title-telas-movil mb-5">telas para rollers</h2>
                    <div className="d-flex justify-content-start mb-movil-5" data-aos="fade-right">
                        <div>
                            <figure>
                                <img src={img4} alt="Tela zebra" className="w-75 h-75 telefono-w-100"/>
                            </figure>
                            <p className="m-0 text-center py-2 text-white bg-blue w-75 telefono-w-100">Tela zebra a 2.85 mt de ancho</p>
                            <div className="d-flex mt-2">
                                <BtnCotiza/>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mb-movil-5" data-aos="fade-left">
                        <div>
                            <figure className="d-flex justify-content-end">
                                <img src={img5} alt="Block Out" className="w-75 h-75 telefono-w-100"/>
                            </figure>
                            <div className="d-flex justify-content-end">
                                <p className="m-0 text-center py-2 text-white bg-blue w-75 telefono-w-100">Block out a 3 mt de ancho</p>
                            </div>
                            <div className="d-flex mt-2 justify-content-end">
                                <BtnCotiza/>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-start mb-movil-5" data-aos="fade-right">
                        <div>
                            <figure>
                                <img src={img6} alt="Tela screen" className="w-75 h-75 telefono-w-100"/>
                            </figure>
                            <p className="m-0 text-center py-2 text-white bg-blue w-75 telefono-w-100">Tela screen de 3 metros de ancho</p>
                            <div className="d-flex mt-2">
                                <BtnCotiza/>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mb-movil-5" data-aos="fade-left">
                        <div>
                            <figure className="d-flex justify-content-end">
                                <img src={img8} alt="Tela saran" className="w-75 h-75 telefono-w-100"/>
                            </figure>
                            <div className="d-flex justify-content-end">
                                <p className="m-0 text-center py-2 text-white bg-blue w-75 telefono-w-100">Block out 2.50 Mt de ancho</p>
                            </div>
                            <div className="d-flex mt-2 justify-content-end">
                                <BtnCotiza/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5 container d-flex justify-content-center container-movil">
                <h2 className="text-uppercase texttitulo text-center title-telas-movil">tela 100% italiana acrílica</h2>
            </section>

            <section className="mb-5 overflow-hidden">
                <div className="container">
                    <div className="d-flex flex-column" data-aos="zoom-in">
                        <div className="mb-3">
                            <h2 className="texttitulo text-center">Beneficios</h2>
                        </div>
                        <div className="d-flex flex-column">
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoCheckmarkCircleSharp size={30} />
                                </span>
                                <span className="cortinasp">Telas acrílicas 100% resistentes a la intemperie.</span>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoCheckmarkCircleSharp size={30} />
                                </span>
                                <span className="cortinasp">Cuidado sencillo: Una tela que no teme al descuido.</span>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoCheckmarkCircleSharp size={30} />
                                </span>
                                <span className="cortinasp">Tratamiento de resistencia al agua, aceite, manchas y moho.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5 overflow-hidden">
               <div className="container">
                    <div className="d-flex justify-content-start" data-aos="fade-right">
                        <div>
                            <figure>
                                <img src={img9} alt="Tela Acrílica" className="w-100 h-100"/>
                            </figure>
                            <p className="m-0 text-center pt-2 text-white bg-blue">Tela acrílica de 1.20 mt de ancho <br></br> 100% italiana</p>
                            <div className="d-flex mt-2">
                                <BtnCotiza/>
                            </div>
                        </div>
                    </div>
               </div>
            </section>

            <section className="mb-5 overflow-hidden">
                <div className="container d-flex gap-3 container-movil-telas">
                    {
                        imgCarouselTelas2.map((data, key) => {
                            return <figure key={key}>
                                <img src={data[0]} className="h-100 w-100" alt={data[1]} />
                            </figure>
                        })
                    }
                </div>
            </section>
            

            <CotizaOption title={'¡Cotiza hoy mismo!'}/>
        </>
    );


};

export default Telas