import img1 from "../img/productos/motores/jielong/17.png";
import img2 from "../img/productos/motores/jielong/accesorios/18.png";
import img3 from "../img/productos/motores/jielong/accesorios/19.png";
import img4 from "../img/productos/motores/jielong/accesorios/21.png";
import img5 from "../img/productos/motores/jielong/accesorios/22.png";
import img6 from "../img/productos/motores/jielong/accesorios/67.png";
import img7 from "../img/productos/motores/jielong/accesorios/23.png";
import CotizaOption from "../hooks/CotizaOption";
import { GoDotFill } from "react-icons/go";
import BtnCotiza from "../hooks/BtnCotizar";
import { useEffect } from "react";


const MotoresJielong = ({ setOther }) => {
    useEffect(() => {
        setOther(false);
    }, [setOther]);

    return (
        <>
            <section className="my-5 overflow-hidden">
                <div className=" container text-center" data-aos="zoom-in">
                    <h2 className="etitulo alinearletraselevador m-0 text-uppercase">MOTOR LATERAL ECR ​600KG-800KG-1000KG</h2>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-right">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img1} alt="jolly"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2>Puede incluir dispositivos opcionales:</h2>
                            <div className="d-flex gap-1 align-items-center">
                                <span>
                                    <GoDotFill size={20} />
                                </span>
                                <h3>Tarjeta con 2 controles.</h3>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                                <span>
                                    <GoDotFill size={20} />
                                </span>
                                <h3>Fotocelda.</h3>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                                <span>
                                    <GoDotFill size={20} />
                                </span>
                                <h3>Sistema Wifi.</h3>
                            </div>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-x-scroll content-table">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th colSpan={4} scope="row" className="text-uppercase text-center"><h2>caracteristicas técnicas</h2></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th className="text-uppercase" scope="row">descripción</th>
                                <td>600KG</td>
                                <td>800KG</td>
                                <td>1000KG</td>
                            </tr>
                            <tr>
                                <th className="text-uppercase" scope="row">fuente de alimentación</th>
                                <td>110VAC - 120VAC</td>
                                <td>110VAC - 120VAC</td>
                                <td>110VAC - 120VAC</td>
                            </tr>
                            <tr>
                                <th className="text-uppercase" scope="row">amperios</th>
                                <td>5.6A</td>
                                <td>10.5A</td>
                                <td>10.5A</td>
                            </tr>
                            <tr>
                                <th className="text-uppercase" scope="row">potencia de entrada</th>
                                <td>813W</td>
                                <td>1325W</td>
                                <td>1325W</td>
                            </tr>
                            <tr>
                                <th className="text-uppercase" scope="row">frecuencia</th>
                                <td>60Hz</td>
                                <td>60Hz</td>
                                <td>60Hz</td>
                            </tr>
                            <tr>
                                <th className="text-uppercase" scope="row">velocidad de apertura</th>
                                <td>5.6r/min </td>
                                <td>4.00r/min </td>
                                <td>4.00r/min</td>
                            </tr>
                            <tr>
                                <th className="text-uppercase" scope="row">altura máxima de elevación</th>
                                <td>6 mts</td>
                                <td>7 mts</td>
                                <td>8 mts</td>
                            </tr>
                            <tr>
                                <th className="text-uppercase" scope="row">peso máximo de cortina</th>
                                <td>600kg(1322 LBS) (20 ​m2)</td>
                                <td>800KG(1764 LBS) (28 ​m2)</td>
                                <td>1000KG(2205 LBS) (40 m2)</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
            <section className="my-5 overflow-hidden">
                <div className=" container text-center" data-aos="zoom-in">
                    <h2 className="etitulo alinearletraselevador m-0 text-uppercase">accesorios</h2>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img2} alt="jielong"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">FOTOCELDAS ​ECR</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">BOTONERA  ​LLAVIN ECR</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img3} alt="jielong"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img4} alt="jielong"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">BOTONERA DE ​3 FUNCIONES</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">TARJETA Y ​CONTROL</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img5} alt="jielong"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img6} alt="jielong"/>
                            </figure>
                        </div>
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">WIFI GATEWAY ECR</h2>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div className="w-50 mb-5 d-flex align-items-start flex-column align-self-center telefono-w-100">
                            <h2 className="text-uppercase fw-bold title-sections">CAJA DE ​HERRAJE</h2>
                            <BtnCotiza/>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img7} alt="jielong"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <CotizaOption title={'¡Cotiza hoy mismo!'}/>
        </>
    );
};

export default MotoresJielong;
